import PropTypes from 'prop-types';

import { Box, Flex } from '@cof/plastic-components';
import { css, styled } from 'styled-components';

import { withText } from '../../text/textStore';
import { useAggregatorConsumerName } from '../../utilities/aggregatorConsumerNameProvider';
import { hasAggregatorLogo } from '../../utilities/utils';
import Logo from '../Logo';
import aggregatorsLogoMap from '../Logo/AggregatorsLogo/aggregatorsLogoMap';
import { useExperiments } from '../../experiments/ExperimentContext/ExperimentContext';

export const StyledHeader = styled.header`
    ${({ theme, $isAggregatorWithLogo }) => css`
        background-color: ${$isAggregatorWithLogo ? theme.colors.global.white : theme.colors.capitalOne.blue750};
        border: ${$isAggregatorWithLogo ? theme.colors.global.white : theme.colors.capitalOne.blue750};
        margin: 0;
        padding: ${theme.space.sm};
        text-align: center;
        font-size: 0;
        position: relative;
        z-index: 1;
        ${theme.breakpoints.mq.tabletMin} {
            padding: ${theme.space.lg} 0;
            padding-left: ${theme.space.lg};
            text-align: left;
        }

        ${theme.components.StyledHeader}
    `}
`;

export const LogoWrapper = styled(Flex)`
    ${({ $hide }) => css`
        max-width: 67.4rem;
        padding: 0.8rem 1.6rem 0 1.6rem;
        margin: auto;
        visibility: ${$hide && 'hidden'};
    `}
`;

const Header = ({ Text: text, mainPage = false, hideLogo = false }) => {
    const [aggregatorConsumerName] = useAggregatorConsumerName();
    const isAggregatorWithLogo = hasAggregatorLogo(aggregatorConsumerName);
    const variant = isAggregatorWithLogo ? 'dark' : 'light';
    const { reasonsToQuoteVariants } = useExperiments();
    const isReasonsToQuoteWithSimpleHeader = reasonsToQuoteVariants?.isReasonsToQuoteWithHeader;

    return (
        <StyledHeader $isAggregatorWithLogo={isAggregatorWithLogo} data-qa-id="header" $mainPage={mainPage}>
            {isReasonsToQuoteWithSimpleHeader && mainPage ? (
                <LogoWrapper data-qa-id="header-logo-wrapper" $hide={hideLogo}>
                    <Flex width="100%" height="100%" justifyContent={['left', null, 'flex-start']} alignItems="center">
                        <a
                            data-qa-id="header-link"
                            href={text({ path: 'Link' })}
                            aria-label={text({ path: 'AriaLabel' })}
                        >
                            <Logo variant={variant} />
                        </a>
                        {isAggregatorWithLogo && (
                            <Box
                                borderLeft="1px solid black"
                                marginLeft="10px"
                                paddingLeft="10px"
                                data-qa-id="aggregator-logo-wrapper"
                            >
                                {aggregatorsLogoMap[aggregatorConsumerName]}
                            </Box>
                        )}
                    </Flex>
                </LogoWrapper>
            ) : (
                <Flex
                    data-qa-id="header-logo-wrapper"
                    width="100%"
                    height="100%"
                    justifyContent={['center', null, 'flex-start']}
                    alignItems="center"
                >
                    <a data-qa-id="header-link" href={text({ path: 'Link' })} aria-label={text({ path: 'AriaLabel' })}>
                        <Logo variant={variant} />
                    </a>
                    {isAggregatorWithLogo && (
                        <Box
                            borderLeft="1px solid black"
                            marginLeft="10px"
                            paddingLeft="10px"
                            data-qa-id="aggregator-logo-wrapper"
                        >
                            {aggregatorsLogoMap[aggregatorConsumerName]}
                        </Box>
                    )}
                </Flex>
            )}
        </StyledHeader>
    );
};

Header.propTypes = {
    Text: PropTypes.func.isRequired,
    mainPage: PropTypes.bool,
    hideLogo: PropTypes.bool
};

const WrappedHeader = withText('Header')(Header);

WrappedHeader.displayName = 'Header';

export default WrappedHeader;
