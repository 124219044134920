import { css, styled } from 'styled-components';
import cssSystem from '@styled-system/css';

import { Box, Card, Flex, Heading, ListItem, ThumbsUpIcon, Text, List, ShieldIcon } from '@cof/plastic-components';
import PropTypes from 'prop-types';
import { withText } from '../../text/textStore';
import FormErrors from '../FormErrors';
import card from '../../assets/webp/capital-one-card.webp';

const FormCard = styled(Card)`
    box-shadow: unset;
    padding: 0;
`;

const GradientText = styled.span`
    background: linear-gradient(96.87deg, #ff8791 22.28%, #ffe433 97.69%);
    -webkit-text-fill-color: transparent;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-background-clip: text;
`;

const CardContainer = styled(Box)`
    ${({ theme }) => css`
        height: 16rem;
        padding: 4rem 10.5rem 0 10.5rem;
        ${cssSystem({ maxWidth: ['34.5rem', null, '67.4rem'] })}
        ${theme.breakpoints.mq.mobileMax} {
            padding: ${theme.space.sm};
            height: 9.7rem;
            margin: auto;
        }
    `}
`;

const GradientContainer = styled(Box)`
    background: linear-gradient(155deg, #ff8791 7.72%, #ffe433 102.75%);
    border-radius: 0.8rem 0.8rem 0 0;
`;

const IconStyling = ({ theme }) => css`
    height: 3.2rem;
    width: 3.2rem;
    color: ${theme.colors.capitalOne.teal450};
    ${theme.breakpoints.mq.mobileMax} {
        height: ${theme.space.md};
        width: ${theme.space.md};
    }
`;

const HeaderListItem = styled(ListItem)`
    ${({ theme }) => css`
        margin-top: ${theme.space.xs};
        margin-bottom: 0;
        div > div:first-child > span {
            ${IconStyling}
        }
        div > div:last-child {
            margin: auto;
        }
    `}
`;

const HeaderListItemOne = ({ Text: text }) => {
    return (
        <HeaderListItem Icon={ThumbsUpIcon}>
            <Text
                color="global.white"
                fontSize={['textMediumMobile', null, 'textLargeDesktop']}
                fontWeight={['500', null, '400']}
            >
                {text({ path: 'QuickResponse' })}
            </Text>
        </HeaderListItem>
    );
};

const HeaderListItemTwo = ({ Text: text }) => {
    return (
        <HeaderListItem Icon={ShieldIcon}>
            <Text
                color="global.white"
                fontSize={['textMediumMobile', null, 'textLargeDesktop']}
                fontWeight={['500', null, '400']}
            >
                {text({ path: 'CreditScore' })}
            </Text>
        </HeaderListItem>
    );
};

const SimpleHeader = ({ Text: text }) => {
    return (
        <FormCard data-qa-id="simple-header" backgroundColor="transparent">
            <Flex flexDirection={'column'} maxWidth="100%">
                <Flex flexDirection={'column'} pb={['md', null, 'lg']} px={['sm', null, 'lg']} textAlign={'left'}>
                    <Heading
                        as="h1"
                        textAlign="left"
                        color="global.white"
                        pb={['sm', null, 'md']}
                        data-qa-id="heading"
                        fontSize={['headingLargeMobile', null, 'headingMediumDesktop']}
                    >
                        {text({ path: 'Title1' })} <br aria-hidden={true} />
                        <GradientText>{text({ path: 'Title2' })}</GradientText>
                    </Heading>
                    <List paddingLeft={[0, 0, 0]}>
                        <HeaderListItemOne Text={text} />
                        <HeaderListItemTwo Text={text} />
                    </List>
                </Flex>
                <FormErrors />
                <GradientContainer>
                    <CardContainer>
                        <img width={'100%'} data-qa-id="header-card-image" aria-hidden={true} alt="" src={card} />
                    </CardContainer>
                </GradientContainer>
            </Flex>
        </FormCard>
    );
};

SimpleHeader.propTypes = {
    Text: PropTypes.func.isRequired
};

HeaderListItemOne.propTypes = {
    Text: PropTypes.func.isRequired
};

HeaderListItemTwo.propTypes = {
    Text: PropTypes.func.isRequired
};

const WrappedSimpleHeader = withText('SimpleHeaderTest')(SimpleHeader);

WrappedSimpleHeader.displayName = 'SimpleHeader';

export default WrappedSimpleHeader;
