import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import useReasonsToQuote from '../reasonsToQuote';

export const ExperimentContext = createContext({});

const ExperimentProvider = ({ children }) => {
    const { isReasonsToQuoteTest, reasonsToQuoteTestVariant, reasonsToQuoteVariants } = useReasonsToQuote();
    const experiments = {
        isReasonsToQuoteTest,
        reasonsToQuoteTestVariant,
        reasonsToQuoteVariants
    };
    return <ExperimentContext.Provider value={experiments}>{children}</ExperimentContext.Provider>;
};

export const useExperiments = () => useContext(ExperimentContext);

export default ExperimentProvider;

ExperimentProvider.propTypes = {
    children: PropTypes.node.isRequired
};
