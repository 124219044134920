import { useBrand } from '@cof/plastic-components';

import useQueryParams from '../../utilities/useQueryParams';
import mkExperiment from '../mkExperiment';

const useReasonsToQuote = () => {
    const brand = useBrand();
    const { setActive, isInTest } = mkExperiment({
        options: { control: 'S', test: ['T', 'U', 'V', 'W', 'Y'] },
        confName: 'experiments.reasonsToQuote.enabled'
    });

    const { quotation, sParameter, oemc, vendorCode } = useQueryParams();
    setActive({ brand, sParameter, quotation, oemc, vendorCode });
    const { sParameter: sParameterPostSetup } = useQueryParams();

    const reasonsToQuoteVariants = {
        isReasonsToQuoteWithHeader: ['T', 'V', 'Y'].includes(sParameterPostSetup?.[1]),
        isReasonsToQuoteWithNavyApply: ['U', 'V'].includes(sParameterPostSetup?.[1]),
        isReasonsToQuoteWithWhiteApply: ['W', 'Y'].includes(sParameterPostSetup?.[1])
    };

    return {
        isReasonsToQuoteTest: isInTest({ brand, sParam: sParameterPostSetup }),
        reasonsToQuoteTestVariant: sParameterPostSetup?.[1],
        reasonsToQuoteVariants
    };
};

export default useReasonsToQuote;
